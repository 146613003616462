<template>
  <div>
    <div>
      <h3 class="card-label">
        {{ $t('job_card.job_card') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('job_card.job_card_code') }}</label>
                <input type="text" disabled v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('job_card.item') }}</label>
                <div class="d-flex search-item-group">
                  <select name="" id="" v-model="f_by" class="custom-select" style="width: 100px;">
                    <option value="name" selected>{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect v-model="item"
                               id="item_id"
                               disabled
                               :placeholder="$t('job_card.item')"
                               :label="f_by?f_by:'sku_code'"
                               track-by="id"
                               :options="[]"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :internal-search="false"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
                <span v-if="validation && validation.item_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.item_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('job_card.job_card_no') }}</label>
                <input type="text" disabled v-model="data.card_no" class="form-control" :class="validation && validation.card_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.card_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.card_no[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.work_order') }}</label>
                <multiselect
                    v-model="work_order"
                    disabled
                    :placeholder="$t('job_card.work_order')"
                    label="name"
                    track-by="id"
                    :options="[]"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.work_order_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.work_order_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.bill_material') }}</label>
                <multiselect
                    v-model="bill_material"
                    disabled
                    :placeholder="$t('job_card.bill_material')"
                    label="name"
                    track-by="id"
                    :options="[]"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.bill_material_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.bill_material_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label for="in_progress_inventory">{{ $t('job_card.in_progress_inventory') }}</label>
                <multiselect v-model="in_progress_inventory"
                             id="in_progress_inventory"
                             disabled
                             :placeholder="$t('job_card.in_progress_inventory')"
                             label="name"
                             track-by="id"
                             :options="[]"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.in_progress_inventory_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.in_progress_inventory_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.operation') }}</label>
                <multiselect
                    v-model="operation"
                    disabled
                    :placeholder="$t('job_card.operation')"
                    label="name"
                    track-by="id"
                    :options="[]"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.operation_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.operation_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.company') }}</label>
                <input type="text" disabled v-model="company_name" class="form-control" :class="validation && validation.company_id ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.company_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.company_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.posting_date') }}</label>
                <input type="date" v-model="data.posting_date" class="form-control" :class="validation && validation.posting_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.posting_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.posting_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.quantity') }}</label>
                <input type="text" v-model="data.quantity" class="form-control" :class="validation && validation.quantity ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.quantity" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.quantity[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.employee') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="employee"
                               id="employee"
                               :placeholder="$t('job_card.employee')"
                               label="name"
                               track-by="id"
                               :options="employees"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.employee_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.employee_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('job_card.status') }}</label>
                <select name="" id="status" disabled v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('job_card.time_log') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeater"><i class="fas fa-plus"></i>{{ $t('add_more') }}</button>
          </div>
          <div class="table-responsive bg-white">
            <table class="table table-custom-padding">
              <thead>
              <tr>
                <th scope="col">{{ $t('job_card.from_time') }}</th>
                <th scope="col">{{ $t('job_card.to_time') }}</th>
                <th scope="col">{{ $t('job_card.time_in_minutes') }}</th>
                <th scope="col">{{ $t('job_card.complete_quantity') }}</th>
                <th scope="col">{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in times" :key="index">

                <td>
                  <input type="time" v-model="item.from_time" class="form-control" :placeholder="$t('job_card.from_time')"/>
                </td>
                <td>
                  <input type="time" v-model="item.to_time" class="form-control" :placeholder="$t('job_card.to_time')"/>
                </td>
                <td>
                  <input type="number" min="0" disabled v-model="item.time_in_minutes" class="form-control" :placeholder="$t('job_card.time_in_minutes')"/>
                </td>
                <td>
                  <input type="number" min="0" v-model="item.complete_quantity" class="form-control" :placeholder="$t('job_card.complete_quantity')"/>
                </td>
                <td>
                  <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i class="fas fa-trash text-danger"></i></button>
                </td>
              </tr>


              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('job_card.notes') }}</label>
                  <textarea v-model="data.notes" class="form-control" rows="5" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>





      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "Form-job-card",
  data() {
    return {
      mainRoute: 'manufacturers/job_cards',
      mainRouteDependency: 'base/dependency',

      data: {
        code: null,
        card_no: null,
        work_order_id: null,
        bill_material_id: null,
        item_id: null,
        operation_id: null,
        posting_date: null,
        company_id: null,
        quantity: null,
        in_progress_inventory_id: null,
        employee_id: null,
        status: null,
        notes: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,

      status_list: [],
      employees: [],

      times: [],
      time_repeater: {id: null, from_time: null, to_time: null, time_in_minutes: null, complete_quantity: null},

      f_by: 'name',

      employee: null,
      in_progress_inventory: null,
      company_name: null,
      operation: null,
      item: null,
      bill_material: null,
      work_order: null,
    }
  },
  watch: {
    employee: function (val) {
      if (val)
        this.data.employee_id = val.id;
      else
        this.data.employee_id = null;
    },

    times: {
      handler(val) {
        if (val.length > 0) {
          this.times.map((row) => {
            if (row.from_time && row.to_time) {
              let startTime = this.$moment(row.from_time, 'hh:mm:ss');
              let endTime = this.$moment(row.to_time, 'hh:mm:ss');
              row.time_in_minutes = endTime.diff(startTime, 'minutes');
            } else {
              row.time_in_minutes = null;
            }
          });
        }
      },
      deep: true
    },

  },
  methods: {
    save() {
      let _repeater_qty = 0;
      let _qty = this.data.quantity ? parseInt(this.data.quantity) : 0;
      this.times.map((row) => {
        if (row.complete_quantity)
          _repeater_qty = parseInt(row.complete_quantity) + _repeater_qty;
      });
      if (_repeater_qty != _qty || _repeater_qty <= 0) {
        this.$warningMessage(this.$t('job_card.the_sum_qty_in_time_log_must_be_equal_main_qty'));
        return false;
      }
      if (this.isEditing) {
        this.update();
      }
    },

    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        times: this.times,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'job-card.index'});
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;

        this.employee = response.data.data.employee;
        this.in_progress_inventory = response.data.data.in_progress_inventory;
        this.company_name = response.data.data.company_name;
        this.operation = response.data.data.operation;
        this.item = response.data.data.item;
        this.bill_material = response.data.data.bill_material;
        this.work_order = response.data.data.work_order;

        this.data.code = response.data.data.code;
        this.data.card_no = response.data.data.card_no;
        this.data.work_order_id = response.data.data.work_order_id;
        this.data.bill_material_id = response.data.data.bill_material_id;
        this.data.item_id = response.data.data.item_id;
        this.data.operation_id = response.data.data.operation_id;
        this.data.posting_date = response.data.data.posting_date;
        this.data.company_id = response.data.data.company_id;
        this.data.quantity = response.data.data.quantity;
        this.data.in_progress_inventory_id = response.data.data.in_progress_inventory_id;
        this.data.employee_id = response.data.data.employee_id;
        this.data.status = response.data.data.status;
        this.data.notes = response.data.data.notes;

        if (response.data.data.times && response.data.data.times.length > 0) {
          this.times = response.data.data.times;
        } else {
          this.addRepeater();
        }


      });
    },

    getEmployees() {
      ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
        this.employees = response.data.data;
      });
    },

    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/work_order_operations_status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    addRepeater() {
      this.times.push(this.time_repeater);
      this.time_repeater = {id: null, from_time: null, to_time: null, time_in_minutes: null, complete_quantity: null};
    },
    removeRepeater(index) {

      if (this.times.length > 1)
        this.times.splice(index, 1);
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.job_card"), route: '/manufactories/job-card'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    // this.addRepeater();

    this.getEmployees();
    this.getStatusList();

    if (this.idEditing) {
      this.getData();
    }

  }
}
</script>

<style scoped>

</style>